import { Entity } from '@/modules/common/domain/entity'
import CustomerInfo from '@/modules/customer/domain/customerInfo'
import ProgramInfo from '@/modules/program/domain/programInfo'
import User from '@/modules/user/domain/user'
import { buildSearchString } from '@/modules/common/values/searchable'

export default class CustomerLog extends Entity {
  text?: string
  args?: Array<any>
  program: ProgramInfo | null
  customer: CustomerInfo | null
  user: User | null

  constructor (data: any) {
    super(data, 'uuid', 'text')
    this.text = data.text
    this.args = data.args
    this.user = data.user ? new User(data.user) : null
    this.program = data.program ? new ProgramInfo(data.program) : null
    this.customer = data.customer ? new CustomerInfo(data.customer) : null

    this.searchString = buildSearchString(this, ['user', 'program', 'customer'])
  }
}
