
import _ from 'lodash'
import Component, { mixins } from 'vue-class-component'
import Company from '@/modules/company/domain/company'
import companyCreateModal from '@/modules/company/components/companyCreateModal.vue'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import loading from '@/modules/common/components/loading.vue'
import { RoleType } from '@/modules/user/type/roleType'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import User from '@/modules/user/domain/user'
import notificationService from '@/modules/common/services/notificationService'

@Component({
  components: { companyCreateModal, loading }
})
export default class CompanyInfoBox extends mixins(confirmMixin, submitProtectionMixin) {
  showCreateModal = false
  selectedItem?: Company | null = null

  @Prop({ type: Company, required: true }) company!: Company
  @State('items', { namespace: 'user' }) users!: User[]

  get admin () {
    return _(this.users).filter(user => user.hasRole(RoleType.COMPANY_ADMIN, this.company.uuid)).first()
  }

  createShow () {
    this.selectedItem = _.cloneDeep(this.company)
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  updateAllPass () {
    this.protect()
    this.confirm('pass.publish.all.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('pass/publishAll', this.company.uuid).then(() => {
          notificationService.success('pass.publish.success')
          this.$emit('close')
        }).finally(this.unprotect)
      }
    }).finally(this.unprotect)
  }

  async created () {
    await this.$store.dispatch('user/clearAll')
  }
}
