import _ from 'lodash'
import { Entity } from '@/modules/common/domain/entity'
import UserInfo from '@/modules/user/domain/userInfo'
import UserRole from '@/modules/user/domain/UserRole'
import { RoleType } from '@/modules/user/type/roleType'
import moment from 'moment'

export default class User extends Entity {
  email?: string
  firstname?: string | null
  password?: string | null
  surname?: string | null
  roles: UserRole[] = []
  lastLogin?: moment.Moment | null
  active?: boolean

  constructor (data: any) {
    super(data, 'uuid', 'email', ['fullName', 'email'])
    this.email = data.email
    this.firstname = data.firstname
    this.surname = data.surname
    this.active = data.active
    if (data.roles) {
      // @ts-ignore
      this.roles = data.roles.map((role: any) => new UserRole(role))
    }
    this.lastLogin = this.convertDate(data.lastLogin)
  }

  get fullName (): string {
    return this.firstname + ' ' + this.surname
  }

  hasRole (roleType: RoleType, companyUuid: string | null = null) {
    return _.some(this.roles, role => role.satisfied(roleType, companyUuid))
  }

  hasAnyRole (roleTypes: RoleType[], companyUuid: string | null = null) {
    return _.some(this.roles, role => role.satisfiedAny(roleTypes, companyUuid))
  }

  hasAnyCompanyRole (roleType: RoleType) {
    return _.some(this.roles, role => !!role.companyUuid && role.satisfied(roleType, role.companyUuid))
  }

  hasLoginPermission () {
    return this.hasAnyCompanyRole(RoleType.COMPANY_ADMIN) || this.hasRole(RoleType.SUPER_ADMIN)
  }

  addRole (roleType: RoleType, companyUuid: string) {
    if (!this.hasRole(roleType, companyUuid)) {
      let userRole = this.userRole(companyUuid)
      if (!userRole) {
        userRole = new UserRole({ companyUuid, roles: [] })
        this.roles.push(userRole)
      }
      userRole.roles.push(roleType)
    }
  }

  removeRole (roleType: RoleType, companyUuid: string) {
    if (this.hasRole(roleType, companyUuid)) {
      const userRole = this.userRole(companyUuid)!
      if (userRole.roles.length === 1) {
        this.roles = _.filter(this.roles, userRole => userRole!.companyUuid !== companyUuid)
      } else {
        userRole.roles = _.without(userRole.roles, roleType)
      }
    }
  }

  userRole (companyUuid: string) : UserRole | undefined {
    return _.find(this.roles, userRole => userRole!.companyUuid === companyUuid)
  }

  get companyUuid (): string | null {
    return _(this.roles)
      .filter('companyUuid')
      .map('companyUuid')
      .first() || null
  }

  get info (): UserInfo {
    return new UserInfo(this)
  }
}
