

import Component from 'vue-class-component'
import Vue from 'vue'
import authService from '@/modules/auth/services/authService'
import { State } from 'vuex-class'
import User from '@/modules/user/domain/user'

@Component
export default class Home extends Vue {
  @State('user', { namespace: 'auth' }) user!: User

  mounted () {
    // redirect to regular
    this.$router.push(authService.mainPath(this.$router.currentRoute, this.user))
  }
}
