import Company from '@/modules/company/domain/company'
import { Entity } from '@/modules/common/domain/entity'
import moment from 'moment'
import ProgramInfo from '@/modules/program/domain/programInfo'

export default class Program extends Entity {
  name?: string
  description?: string
  rewardExpiration?: number
  maxStamps?: number
  company: Company | null
  validFrom?: moment.Moment | null
  validTo?: moment.Moment | null

  constructor (data: any) {
    super(data, 'uuid', 'name')
    this.name = data.name
    this.description = data.description
    this.rewardExpiration = data.rewardExpiration
    this.maxStamps = data.maxStamps
    this.validFrom = this.convertDate(data.validFrom)
    this.validTo = this.convertDate(data.validTo)
    this.company = data.company ? new Company(data.company) : null
  }

  get valid () {
    const now = moment()
    return now.isSameOrAfter(this.validFrom!.startOf('day')!) && now.isSameOrBefore(this.validTo!.endOf('day')!)
  }

  overlap (other: Program) {
    return other.validFrom!.isSameOrBefore(this.validTo!.endOf('day')) && other.validTo!.isSameOrAfter(this.validFrom!.startOf('day'))
  }

  get info (): ProgramInfo {
    return new ProgramInfo(this)
  }
}
