
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import companiesTable from '@/modules/company/components/companiesTable.vue'
import Component from 'vue-class-component'
import page from '../../common/components/page.vue'
import Vue from 'vue'

@Component({
  components: { companiesTable, page }
})
export default class CompaniesPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'admin.label' }, { textKey: 'companies.label' }])
  query: string | null = null
}
