import _ from 'lodash'
import { RoleType } from '@/modules/user/type/roleType'

export default class UserRole {
  companyUuid: string | null
  roles: RoleType[] = []

  constructor (data: any) {
    this.companyUuid = data.companyUuid || null
    if (data.roles) {
      // @ts-ignore
      this.roles = data.roles.map((role: string) => RoleType[role])
    }
  }

  satisfied (roleType: RoleType, companyUuid: string | null = null): boolean {
    return this.companyUuid === companyUuid && this.roles.includes(roleType)
  }

  satisfiedAny (roleTypes: RoleType[], companyUuid: string | null = null) {
    return this.companyUuid === companyUuid && _.intersection(this.roles, roleTypes).length > 0
  }
}
