import Vue from 'vue'
import Vuex from 'vuex'
import plugins from './plugins'
import * as modules from './storeModules'
import RootState from '@/store/rootState'

Vue.use(Vuex)

const store = new Vuex.Store<RootState>({
  plugins,
  modules
})

export default store
