
import Color from '@/modules/common/domain/color'
import Component from 'vue-class-component'
import numberField from '@/modules/common/components/form/numberField.vue'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'
import StaticField from '@/modules/common/components/form/staticField.vue'

@Component({
  components: { StaticField, numberField }
})
export default class AddressForm extends Vue {
  @Prop({ type: Color, required: true }) color!: Color
  @Prop({ type: Number, default: 0 }) index!: number
}
