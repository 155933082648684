
import Component from 'vue-class-component'
import footer from '@/modules/app/components/footer.vue'
import { Getter, State } from 'vuex-class'
import i18n from '@/i18n'
import liveChat from '@/modules/app/components/liveChat.vue'
import loading from '@/modules/common/components/loading.vue'
import navbar from './navbar.vue'
import sidebar from '@/modules/app/components/sidebar.vue'
import Vue from 'vue'

@Component({
  components: { sidebar, appFooter: footer, liveChat, loading, navbar },
  metaInfo: {
    title: i18n.message('page.title')
  }
})
export default class App extends Vue {
  @Getter('loggedIn', { namespace: 'auth' }) loggedIn!: boolean
  @State('logoutInProgress', { namespace: 'auth' }) logoutInProgress!: boolean
}

