
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import Customer from '@/modules/customer/domain/customer'
import CustomersTable from '@/modules/customer/components/customersTable.vue'
import { Getter, State } from 'vuex-class'
import InfoBox from '@/modules/common/components/infoBox.vue'
import loading from '@/modules/common/components/loading.vue'
import noRecords from '@/modules/common/components/noRecords.vue'
import page from '@/modules/common/components/page.vue'
import Vue from 'vue'
import Company from '@/modules/company/domain/company'

@Component({
  components: { InfoBox, CustomersTable, page, noRecords, loading }
})
export default class CustomersPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'customers.label' }])

  @State('managed', { namespace: 'company' }) company!: Company
  @Getter('all', { namespace: 'customer' }) allCustomers!: Array<Customer>
}
