
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Company from '@/modules/company/domain/company'
import { FORMAT_SYSTEM_DATE } from '@/utils'
import Component, { mixins } from 'vue-class-component'
import CustomerLog from '@/modules/customer/domain/customerLog'
import DateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import entityTableMixin from '@/modules/common/mixins/entityTableMixin'
import { State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import noRecords from '@/modules/common/components/noRecords.vue'
import Range from '@/modules/common/components/form/range'
import RangeFilter from '@/modules/common/domain/RangeFilter'
import The from '@/modules/common/components/table/the.vue'

@Component({
  components: { DateRangeField, The, noRecords, loading }
})
export default class CustomersTable extends mixins<entityTableMixin<CustomerLog>>(entityTableMixin, bookmarkableComponent) {
  entityModuleName = 'customerLog'
  activeSupport = false
  @State('managed', { namespace: 'company' }) company!: Company

  filter = new RangeFilter()

  defaultFilter () {
    this.filter.range = new Range(moment().subtract(7, 'day').startOf('day'))
  }

  detail (id: string) {
    this.$router.push({ name: 'customer', params: { customerId: id } })
  }

  async beforeFetch () {
    await this.$store.dispatch('customerLog/clearAll')
    const params: any = { company: this.company.uuid, from: FORMAT_SYSTEM_DATE(this.filter!.range!.from) }
    if (this.filter.range!.to) params.to = FORMAT_SYSTEM_DATE(this.filter.range!.to)
    this.fetchActionParams = new EntityFetchParams(true, params)
  }
}
