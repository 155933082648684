
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class InfoBox extends Vue {
  @Prop(String) iconClass?: string
  @Prop({ type: String, default: 'bg-teal' }) bgClass?: string
  @Prop({ type: String }) text?: string
  @Prop({ type: String }) number?: string
  @Prop({ type: String }) description?: string
  @Prop({ type: Boolean, default: false }) showProgress?: boolean
  @Prop({ type: Number, default: 0 }) progressWidth?: number
  @Prop({ type: Boolean, default: false }) medium?: boolean
  @Prop({ type: Boolean, default: false }) fill?: boolean
}
