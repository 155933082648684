
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import { RoleType } from '@/modules/user/type/roleType'
import permissionTypes from '@/modules/user/type/roleTypes'
import User from '../domain/user'
import Vue from 'vue'

@Component({
  components: { loading }
})
export default class PermissionsEditBox extends Vue {
  permissionTypes = permissionTypes

  @Prop({ type: String, required: true }) companyUuid!: string
  @Prop({ type: User, required: true }) user!: User
  @Prop({ type: Array, required: true }) permissions!: Array<RoleType>

  togglePermission (role: RoleType) {
    if (this.hasPermission(role)) {
      this.user.removeRole(role, this.companyUuid)
    } else {
      this.user.addRole(role, this.companyUuid)
    }
  }

  hasPermission (role: RoleType) {
    return this.user && this.user.hasRole(role, this.companyUuid!)
  }
}
