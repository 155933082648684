
import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import infoBox from '@/modules/common/components/infoBox.vue'
import Vue from 'vue'

@Component({
  components: { infoBox }
})
export default class NewsDataPage extends Vue {
  @State('managed', { namespace: 'company' }) company!: Company
  @Getter('publishedLastMonth', { namespace: 'newsData' }) publishedLastMonth!: number
  @Getter('availableLastMonth', { namespace: 'newsData' }) availableLastMonth!: (maxPerMonth: number) => number
  @Getter('availableLastMonthInPercentage', { namespace: 'newsData' }) availableLastMonthInPercentage!: (maxPerMonth: number) => number

  async created () {
    await this.$store.dispatch('newsData/getLastMonth', new EntityFetchParams(false, { company: this.company!.uuid }))
  }
}
