import companySettingPage from '../components/companySettingPage.vue'
import companiesPage from '@/modules/company/components/companiesPage.vue'
import companyPage from '@/modules/company/components/companyPage.vue'
import { RoleType } from '@/modules/user/type/roleType'

export default [
  {
    path: '/settings/company',
    component: companySettingPage,
    meta: {
      requiresLoggedIn: true,
      requiredCompanyRole: RoleType.COMPANY_ADMIN
    }
  },
  {
    path: '/admin/companies',
    component: companiesPage,
    name: 'companies',
    meta: {
      requiresLoggedIn: true,
      requiredRole: RoleType.SUPER_ADMIN
    }
  },
  {
    path: '/admin/companies/:companyId',
    component: companyPage,
    name: 'company',
    props: true,
    meta: {
      requiresLoggedIn: true,
      requiredRole: RoleType.SUPER_ADMIN
    }
  }
]
