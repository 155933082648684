
import _ from 'lodash'
import { normalize } from '@/utils'
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Labeled from '@/modules/common/values/labeled'

@Component({
  components: { ValidationProvider, vSelect }
})
export default class SelectField extends Vue {
  selectedValue: any | null = null

  @Prop({ default: null }) value?: any
  @Prop({ type: String }) label?: string
  @Prop({ type: String, default: 'label' }) labelProp?: string
  @Prop({ type: String, default: 'label', required: true }) fieldId!: string
  @Prop({ type: String }) placeholder?: string
  @Prop({ type: String }) scope?: string
  @Prop({ type: Array, required: true, default: () => [] }) options?: Array<any>
  @Prop({ type: String, default: '' }) validation?: string
  @Prop({ type: Boolean, default: false }) codelist?: boolean
  @Prop({ type: Boolean, default: false }) readonly?: boolean
  @Prop({ type: Boolean, default: true }) searchable?: boolean
  @Prop({ type: Boolean, default: true }) clearable?: boolean
  @Prop({ type: Boolean, default: false }) translate?: boolean
  @Prop({
    type: Function,
    default (option: Labeled, label: string, search: string) {
      return normalize(label).indexOf(normalize(search)) > -1
    }
  }) filterBy?: Function

  @Prop({ type: Boolean, default: false }) multiple?: boolean
  @Prop({ type: Boolean, default: false }) disabled?: boolean
  @Prop({ type: Function, default: () => {} }) onSearch?: Function
  @Prop({ type: Boolean, default: true }) showLabel!: boolean

  updateValue (value: any) {
    let emittedValue = null
    if (!_.isNil(value) && this.codelist) {
      emittedValue = this.multiple ? _.map(value, 'value') : value.value
    } else {
      emittedValue = value
    }
    this.selectedValue = value
    this.$emit('input', emittedValue)
  }

  valueUpdated (newValue: any) {
    if (!_.isNil(newValue)) {
      if (this.multiple && this.codelist) {
        // multi-select and code-list
        this.selectedValue = _.filter(this.options, option => newValue.indexOf(option.value) >= 0)
      } else if (this.multiple && !this.codelist) {
        // only multi-select
        const selectedIds = _.map(newValue, 'value')
        this.selectedValue = _.filter(this.options, option => selectedIds.indexOf(option.value) >= 0)
      } else if (!this.multiple && this.codelist) {
        // only code-list
        this.selectedValue = _.find(this.options, { value: newValue })
      } else {
        // for entity select
        this.selectedValue = newValue
      }
    } else {
      // new value is null or undefined
      this.selectedValue = null
    }
  }

  get requiredVal () {
    return ((this.validation || '').indexOf('required') >= 0)
  }

  @Watch('value')
  onValueChanged (newValue?: any) {
    this.valueUpdated(newValue)
  }

  // for situations, where value is set earlier then options -> e.g. filters with dynamic values from server
  // we need to simulate setting of value again
  @Watch('options')
  onOptionsChanged (newValues?: Array<any>) {
    if (newValues && newValues.length && this.value !== null) {
      this.valueUpdated(this.value)
    }
  }

  // find regular option -> referenced value can be only object with id/value
  findOption (option: any) {
    return _.find(this.options, { value: option.value }) || option
  }

  // for initial fill -> watch doesn't work here
  mounted () {
    if (this.codelist) {
      this.selectedValue = this.multiple ? _.filter(this.options, option => this.value.indexOf(option.value) >= 0) : _.find(this.options, { value: this.value })
    } else {
      this.selectedValue = this.value
    }
  }
}
