import { AUTH_CLEAR_TOKEN, AUTH_LOG_OUT, AUTH_STORE_ME, AUTH_STORE_TOKEN } from './authMutationTypes'
import AuthState from '@/modules/auth/store/authState'

const mutations = {
  async [AUTH_STORE_TOKEN] (state: AuthState, action: any) {
    state.accessToken = action.accessToken
    state.accessTokenValidTo = action.accessTokenValidTo
    if (action.refreshToken) {
      state.refreshToken = action.refreshToken
      state.refreshTokenValidTo = action.refreshTokenValidTo
    }
  },
  async [AUTH_CLEAR_TOKEN] (state: AuthState) {
    state.accessToken = null
    state.accessTokenValidTo = null
    state.refreshToken = null
    state.refreshTokenValidTo = null
  },
  async [AUTH_STORE_ME] (state: AuthState, action: any) {
    state.user = action.user
  },
  async [AUTH_LOG_OUT] (state: AuthState) {
    state.user = null
    state.accessToken = null
    state.accessTokenValidTo = null
    state.refreshToken = null
    state.refreshTokenValidTo = null
    state.logoutInProgress = true
  }
}

export default mutations
