import Company from '@/modules/company/domain/company'

class Certificate {
  company: Company

  constructor (company: Company) {
    this.company = company
  }
}

export default Certificate
