import Company from '@/modules/company/domain/company'
import { MAX_NEWS_PER_MONTH } from '@/config'
import EntityService from '@/modules/common/services/entityService'
import companyResource from '@/modules/company/services/companyResource'

class CompanyService extends EntityService<Company> {
  newCompany () {
    return new Company({ maxNewsPerMonth: MAX_NEWS_PER_MONTH })
  }
}

export default new CompanyService(companyResource, Company)
