
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Component from 'vue-class-component'
import Customer from '@/modules/customer/domain/customer'
import customerAccountBox from '@/modules/customer/components/customerAccountBox.vue'
import customerEventsTab from '@/modules/customer/components/customerEventsTab.vue'
import CustomerInfoBox from '@/modules/customer/components/customerInfoBox.vue'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import Vue from 'vue'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import Company from '@/modules/company/domain/company'
import CompanyApply from '@/modules/company/domain/companyApply'

@Component({
  components: { customerEventsTab, customerAccountBox, CustomerInfoBox, loading, page }
})
export default class CustomerPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'customers.label', routeName: 'customers' }, { textKey: 'customers.detail.label' }])

  @Prop({ type: String, required: true }) customerUuid?: string

  @State('selected', { namespace: 'customer' }) customer!: Customer
  @State('managed', { namespace: 'company' }) company!: Company
  @State('selected', { namespace: 'companyApply' }) companyApply!: CompanyApply

  async created () {
    await this.$store.dispatch('companyApply/clearAll')
    await this.$store.dispatch('customer/clearSelected')
    await this.$store.dispatch('customer/select', this.customerUuid)
    await this.$store.dispatch('companyApply/getAll', new EntityFetchParams(true, { companyUuid: this.company!.uuid, customerUuid: this.customerUuid }))
    await this.$store.dispatch('program/getAll', new EntityFetchParams(false, { company: this.company!.uuid }))
  }
}
