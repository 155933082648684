
import Component from 'vue-class-component'
import { HELP_WEB_URL } from '@/config'
import { State } from 'vuex-class'
import User from '@/modules/user/domain/user'
import Vue from 'vue'

@Component
export default class Navbar extends Vue {
  helpWebUrl = HELP_WEB_URL

  @State('user', { namespace: 'auth' }) user!: User

  async logout () {
    await this.$store.dispatch('auth/logOut')
    location.reload() // need to be refreshed because live-chat iframe need to be deleted
  }
}
