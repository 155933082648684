import companyApplyService from '@/modules/company/services/companyApplyService'
import { Module } from 'vuex'
import actions from '@/modules/common/store/entityActions'
import mutations from '@/modules/common/store/entityMutations'
import getters from '@/modules/common/store/entityGetters'
import RootState from '@/store/rootState'
import EntityState from '@/modules/common/store/entityState'
import CompanyApply from '@/modules/company/domain/companyApply'

const companyModule: Module<EntityState<CompanyApply>, RootState> = {
  namespaced: true,
  state: new EntityState(),
  mutations,
  getters,
  actions: actions(companyApplyService, 'companyApply')
}

export default companyModule
