import Company from '@/modules/company/domain/company'
import Contact from '@/modules/pass/domain/contact'
import { Entity } from '@/modules/common/domain/entity'
import _ from 'lodash'
import moment from 'moment'
import PassSection from '@/modules/pass/domain/passSection'

export default class Pass extends Entity {
  private static readonly MAX_SECTIONS = 5
  private static readonly MAX_CONTACTS = 5

  company?: Company | null
  sections: Array<PassSection> = []
  contacts: Array<Contact> = []
  updated?: moment.Moment | null
  published?: moment.Moment | null

  constructor (data: any) {
    super(data)
    this.company = data.company ? new Company(data.company) : null
    this.sections = data.sections ? data.sections.map((section: any) => new PassSection(section)) : []
    this.contacts = data.contacts ? data.contacts.map((contact: any) => new Contact(contact)) : []
    this.updated = this.convertDate(data.updated)
    this.published = this.convertDate(data.published)
  }

  get newSectionAvailable () {
    return this.sections.length < Pass.MAX_SECTIONS
  }

  get newContactAvailable () {
    return this.contacts.length < Pass.MAX_CONTACTS
  }

  get isDraft () {
    return !this.isPublished
  }

  get isPublished () {
    return !_.isNil(this.published)
  }
}
