
import addressForm from '@/modules/company/components/addressForm.vue'
import colorForm from '@/modules/common/components/colorForm.vue'
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import formModal from '@/modules/common/components/formModal.vue'
import numberField from '@/modules/common/components/form/numberField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import textAreaField from '@/modules/common/components/form/textAreaField.vue'
import textField from '@/modules/common/components/form/textField.vue'

@Component({
  components: { addressForm, colorForm, formModal, numberField, staticField, textAreaField, textField }
})
export default class CompanyCreateModal extends mixins(createModal) {
  moduleName = 'company'
}
