import Vue from 'vue'
import { API_ROOT } from '@/config'

const contextPath = 'users'

const customActions = {
  me: { method: 'GET', url: API_ROOT + contextPath + '/me' },
  myPasswordChange: { method: 'PUT', url: API_ROOT + contextPath + '/{uuid}/password' }
}

export default (<any>Vue).resource(API_ROOT + contextPath + '/{uuid}', {}, customActions)
