export const API_ROOT = process.env.VUE_APP_API_ROOT
export const ACCESS_TOKEN_URL = API_ROOT + 'auth/access-token'
export const REFRESH_TOKEN_URL = API_ROOT + 'auth/refresh-token'
export const LOGIN_URL = ACCESS_TOKEN_URL
export const LOGOUT_URL = 'auth/logout'
export const AUTH_STORAGE_KEY = 'stammgast_auth_data'
export const HELP_WEB_URL = process.env.VUE_APP_HELP_WEB_URL
export const REGISTRATION_WEB_URL = process.env.VUE_APP_REGISTRATION_WEB_URL
export const SUPPORT_BOX_JS_URL = process.env.VUE_APP_SUPPORT_BOX_JS_URL
export const SUPPORT_BOX_CHAT_ID = process.env.VUE_APP_SUPPORT_BOX_CHAT_ID
export const SUPPORT_BOX_CHAT_SECRET = process.env.VUE_APP_SUPPORT_BOX_CHAT_SECRET
export const MAX_NEWS_PER_DAY = 1
export const MAX_NEWS_PER_MONTH = 5
export const COLOR = '#007bff'
export const LOCALE = 'cs'
export const CERT_EXPIRATION_DAY_OFFSET = 30
