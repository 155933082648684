
import { FORMAT_SYSTEM_DATE } from '@/utils'
import _ from 'lodash'
import bookmarkableComponent from '@/modules/common/mixins/bookmarkableComponent'
import Company from '@/modules/company/domain/company'
import Component, { mixins } from 'vue-class-component'
import dateRangeField from '@/modules/common/components/form/dateRangeField.vue'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import { Getter, State } from 'vuex-class'
import loading from '@/modules/common/components/loading.vue'
import moment from 'moment'
import newsCreateModal from '@/modules/news/components/newsCreateModal.vue'
import NewsData from '@/modules/news/domain/newsData'
import newsDataService from '@/modules/news/services/newsDataService'
import notificationService from '@/modules/common/services/notificationService'
import { NewsDataType, newsDataTypes } from '@/modules/news/types/newsDataType'
import noRecords from '@/modules/common/components/noRecords.vue'
import Range from '@/modules/common/components/form/range'
import RangeFilter from '@/modules/common/domain/RangeFilter'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import User from '@/modules/user/domain/user'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { dateRangeField, loading, newsCreateModal, noRecords }
})
export default class NewsTable extends mixins(confirmMixin, bookmarkableComponent, submitProtectionMixin) {
  newsDataTypes = newsDataTypes
  showCreateModal = false
  selectedItem: NewsData | null = null
  lastPublishedIndex = -1
  filter = new RangeFilter()

  @State('managed', { namespace: 'company' }) company!: Company
  @State('user', { namespace: 'auth' }) currentUser!: User
  @Getter('all', { namespace: 'newsData' }) newsData!: Array<NewsData>
  @Getter('availableLastMonth', { namespace: 'newsData' }) availableLastMonth!: (maxPerMonth: number) => number
  @Getter('availableToday', { namespace: 'newsData' }) availableToday!: number

  @Watch('newsData')
  onNewsDataChanged () {
    this.lastPublishedIndex = _.findIndex(this.newsData, { state: NewsDataType.PUBLISHED })
  }

  createShow (newsData?: NewsData) {
    this.selectedItem = newsData ? _.cloneDeep(newsData) : newsDataService.newNewsData(this.company.uuid!)
    this.showCreateModal = true
  }

  publish (newsData?: NewsData) {
    // todo will be removed and improved with licence model
    if (this.availableLastMonth(this.company.maxNewsPerMonth!) <= 0 || this.availableToday <= 0) {
      throw new Error('news.publish.not.available')
    }

    this.confirm('news.publish.confirmation').then((value) => {
      if (value) {
        this.$store.dispatch('newsData/publish', newsData).then((result) => {
          if (result) {
            this.$emit('close')
            notificationService.success('news.publish.success')
          }
        }).finally(this.unprotect)
      }
    })
  }

  async fetch () {
    const params: any = { company: this.company.uuid }
    if (this.filter.range!.from) params.from = FORMAT_SYSTEM_DATE(this.filter.range!.from)
    if (this.filter.range!.to) params.to = FORMAT_SYSTEM_DATE(this.filter.range!.to)
    await this.$store.dispatch('newsData/getAll', new EntityFetchParams(true, params))
  }

  createHide () {
    this.showCreateModal = false
  }

  defaultFilter () {
    this.filter.range = new Range(moment().subtract(3, 'month').startOf('month'))
  }

  async created () {
    await this.$store.dispatch('user/getAll', new EntityFetchParams(false, { company: this.company!.uuid }))
    await this.fetch()
  }
}
