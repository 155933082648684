import Location from '@/modules/location/domain/location'
import EntityService from '@/modules/common/services/entityService'
import locationResource from '@/modules/location/services/locationResource'

class LocationService extends EntityService<Location> {
  newLocation (companyUuid: string) {
    return new Location({ company: { uuid: companyUuid } })
  }
}

export default new LocationService(locationResource, Location)
