import Address from '@/modules/company/domain/address'
import { Entity } from '@/modules/common/domain/entity'
import AppleData from '@/modules/company/domain/appleData'
import GoogleData from '@/modules/company/domain/googleData'
import { REGISTRATION_WEB_URL } from '@/config'
import CompanyColors from '@/modules/company/domain/companyColors'
import Location from '@/modules/location/domain/location'
import CertificateData from '@/modules/company/domain/certificateData'

export default class Company extends Entity {
  name?: string
  urlName?: string
  companyId?: string
  vatId?: string
  terms?: string
  maxNewsPerMonth?: number
  address: Address
  branches: Address[]
  locations: Location[]
  apple: AppleData
  google: GoogleData
  colors: CompanyColors
  certificateData: CertificateData | null

  constructor (data: any) {
    super(data, 'name')
    this.name = data.name
    this.urlName = data.urlName
    this.companyId = data.companyId
    this.vatId = data.vatId
    this.terms = data.terms
    this.maxNewsPerMonth = data.maxNewsPerMonth
    this.address = data.address ? new Address(data.address) : new Address()
    this.apple = data.apple ? new AppleData(data.apple) : new AppleData({})
    this.google = data.google ? new GoogleData(data.google) : new GoogleData()
    this.branches = data.branches ? data.branches.map((branch: any) => new Address(branch)) : []
    this.locations = data.locations ? data.locations.map((location: any, index: number) => new Location({ id: index + '', ...location })) : []
    this.colors = data.colors ? new CompanyColors(data.colors) : new CompanyColors({})
    this.certificateData = data.certificateData ? new CertificateData(data.certificateData.uuid, super.convertDate(data.certificateData.validTo)!) : null
    this.extendSearchString()
  }

  extendSearchString () {
    this.searchString = this.name || ''
  }

  get stammgastUrl () {
    return this.urlName ? `${REGISTRATION_WEB_URL}${this.urlName}` : null
  }

  updateColors () {
    this.apple.backgroundColor = this.colors.background!.rgbString
    this.apple.foregroundColor = this.colors.text!.rgbString
    this.apple.labelColor = this.colors.label!.rgbString

    this.google.hexBackgroundColor = this.colors.background!.hexString
  }
}
