import authService from '@/modules/auth/services/authService'
import notificationService from '@/modules/common/services/notificationService'
import { Store } from 'vuex'
import { Route } from 'vue-router'

export default (store: Store<any>) => {
  return (to: Route, from: Route, next: Function) => {
    if (to.meta?.requiresLoggedIn && !store.getters['auth/loggedIn']) {
      next(authService.loginPath(to))
    } else if (to.meta?.requiredCompanyRole && !store.getters['auth/hasCurrentCompanyRole'](to.meta.requiredCompanyRole)) {
      notificationService.error('error.auth.invalid')
    } else if (to.meta?.requiredAnyCompanyRole && !store.getters['auth/hasAnyCurrentCompanyRole'](...to.meta.requiredAnyCompanyRole)) {
      notificationService.error('error.auth.invalid')
    } else if (to.meta?.requiredRole && !store.getters['auth/hasRole'](to.meta.requiredRole)) {
      notificationService.error('error.auth.invalid')
    } else if (to.name === 'login' && store.getters['auth/loggedIn']) {
      next('/')
    } else {
      next()
    }
  }
}
