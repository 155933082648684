import entityactions from '@/modules/common/store/entityActions'
import Pass from '@/modules/pass/domain/pass'
import passService from '@/modules/pass/services/passService'

const actions = entityactions(passService, 'pass')

actions.publish = async function ({ dispatch }, pass: Pass) {
  const publishedPass = await passService.publish(pass)
  await dispatch('pass/refresh', null, { root: true })
  return publishedPass
}

// eslint-disable-next-line no-empty-pattern
actions.publishAll = async function ({ }, companyUuid: string) {
  await passService.publishAll(companyUuid)
}

export default actions
