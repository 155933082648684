import { FORMAT_FILE_SIZE } from '@/utils'

export default {
  'error.auth.permission': 'Uživatel nemá opávnění pro vstup do aplikace.',
  'error.auth.invalid': 'Neplatné přihlasovací údaje.',

  'error.certificate.apn.url.generate': 'APN Url nelze vygenerovat.',
  'error.certificate.generate': 'Certifikát se nepovedlo vygenerovat.',

  'error.data.invalid': 'Dáta sa nepodařilo spracovať.',
  'error.data.notFound': 'Nepřístupná data.',
  'error.data.payloadTooLarge': 'Neplatná data.',

  'error.program.valid.from.invalid': 'Neplatné datum platnosti.',
  'error.program.valid.to.invalid': 'Datum je v minulosti.',
  'error.program.date.overlap': 'Datum platnosti sa překrývá s jiným programem.',

  'error.user.create': 'Uživatele se nepovedlo vytvořit.',
  'error.user.create.email.used': 'Uživatel se zadanou e-mailovou adresou již v systému existuje.',
  'error.user.roles.empty': 'Uživatel nemá aktivní žádnou roli.',

  'address.street.label': 'Ulice',
  'address.houseNumber.label': 'Číslo domu',
  'address.zipCode.label': 'PSČ',
  'address.city.label': 'Město',
  'address.country.label': 'Stát',

  'certificate.apple.label': 'Apple certifikát',
  'certificate.valid.to.label': 'Platnost certifikátu',
  'certificate.apn.url.label': 'URL do APN',
  'certificate.apn.url.link': 'apn-link',
  'certificate.generate': 'Načíst certifikát',

  'confirmation.title': 'Upozornění',
  'confirmation.button.yes': 'Potvrdit',
  'confirmation.button.no': 'Zrušit',

  'color.red.label': 'Red',
  'color.green.label': 'Green',
  'color.blue.label': 'Blue',
  'color.hex.label': 'HEX',

  'common.id.label': 'ID',
  'common.data.notRecords': 'Žádny záznam neexistuje.',
  'common.detail.label': 'Detail',
  'common.search.label': 'Vyhledej',
  'common.search.placeholder': 'Vyhledej',
  'common.edit.label': 'Upravit',
  'common.position.up': 'Přesun nahoru',
  'common.position.down': 'Přesun dolů',
  'common.showAll': 'zobrazit vše',
  'common.cancel': 'Zrušit',
  'common.delete': 'Smazat',
  'common.edit': 'Změnit',
  'common.save': 'Uložit',
  'common.days': 'dní',
  'common.show.inactive': 'Zobrazit neaktivní',
  'common.inactive': 'Vyřazeno',
  'common.allowed': 'Povoleno',
  'common.denied': 'Zakázáno',
  'common.help': 'Nápoveda',
  'common.range.label': 'Období',
  'common.date': 'Datum',

  'companies.title': 'Společnosti',

  'company.address.label': 'Sídlo (fakturační adresa)',
  'company.color.setting.title': 'Nastavení barev',
  'company.color.background.label': 'Pozadí',
  'company.color.text.label': 'Text',
  'company.color.label.label': 'Labely',
  'company.companyId.label': 'IČ',
  'company.description.label': 'Detailní informace',
  'company.email.label': 'E-mail',
  'company.locations.label': 'Lokace',
  'company.name.label': 'Název',
  'company.new.title': 'Nová společnost',
  'company.edit.title': 'Upravit společnost',
  'company.phone.label': 'Telefón',
  'company.terms.edit.title': 'Podmínky členství',
  'company.terms.label': 'Podmínky členství',
  'company.urlName.label': 'Jméno do URL',
  'company.logoUrl.label': 'URL loga',
  'company.vatId.label': 'DIČ',
  'company.stammgastUrl.label': 'URL',
  'company.stammgastUrl.description': 'URL pro registraci',
  'company.admin.label': 'Admin',
  'company.programs.label': 'Vernostní programy',
  'company.users.label': 'Uživatelé',
  'company.apple.certificate.expiration.warn': 'Platnost certifikát apple vyprší {0}.',
  'company.apple.certificate.expiration.err': 'Platnost certifikát apple vypršela.',
  'company.max-news-per-month.label': 'Počet předplacených aktualít za měsíc',

  'contact.new': 'Nový kontakt',
  'contact.name.label': 'Popis zobrazený na kartičce',
  'contact.section.title.label': 'Název',
  'contact.section.content.label': 'Text',
  'contact.type.label': 'Typ',
  'contact.value.label': 'Webová adresa, telefonní číslo, nebo e-mail',

  'data.edit.success': 'Změny byly uložené.',

  'emoji.icon.select': 'Vyber ikonu',

  'entity.delete.confirmation': 'Opravdu chcete odstranit položku?',
  'entity.delete.success': 'Položka byla úspěšne smazána.',
  'entity.edit.success': 'Položka byla změnená.',

  'location.edit.title': 'Upravit lokaci',
  'location.name.label': 'Název',
  'location.new.title': 'Nová lokace',
  'location.notification-text.label': 'Text notifikace',
  'location.latitude.label': 'Latitude',
  'location.longitude.label': 'Longitude',
  'location.altitude.label': 'Altitude',

  'login.title': 'Stammgast administrace',
  'login.username.label': 'Přihlasovací jméno',
  'login.username.placeholder': 'Zadej svoje přihlasovací jméno',
  'login.password.label': 'Heslo',
  'login.password.placeholder': 'Zadej svoje heslo',
  'login.submit': 'Přihlásit',

  'navbar.logout.label': 'Odhlásit',

  'news.title': 'Aktuality',
  'news.status.label': 'Stav',
  'news.publish.label': 'Odeslat',
  'news.publish.confirmation': 'Aktualitu můžete publikovat pouze 1x za den. Aktualita bude odeslána všem uživatelům. Chcete opravdu pokračovat?',
  'news.publish.success': 'Aktualita byla odeslána.',
  'news.published.label': 'Datum',
  'news.remaining.box.title': 'Zbývající předplacené aktuality',
  'news.remaining.box.description': 'Celkem odesláno tento měsíc: {0}',
  'news.content.label': 'Aktualita',
  'news.terms.edit.title': 'Upravit aktualitu',
  'news.terms.create.title': 'Přidat aktualitu',

  'setting.company.title': 'Nastavení firmy',
  'setting.company.licence.title': 'Licence a platba',
  'setting.company.conditions.title': 'Podmínky členství (pro registraci a stažení karty)',

  'customer.created.label': 'Datum registrace',
  'customer.email.label': 'E-mail',
  'customer.events.overview.title': 'Přehled událostí',
  'customer.fullName.label': 'Jméno',
  'customer.label': 'Zákazník',
  'customer.lastActivity.label': 'Poslední aktivita',
  'customer.title': 'Detail',
  'customer.account.title': 'Stav konta',
  'customer.number.title': 'Počet zákazníků',

  'customers.title': 'Registrovaní zákazníci',

  'customerLog.text.label': 'Událost',

  'customer-log.state.update': 'Oprava stavu konta:<br><del>Razítko: {2}x, odměny aktuálně: {3}x</del><br>Razítko: {0}x, odměny aktuálně: {1}x',
  'customer-log.stamp.add': 'Razítko: {0}x',
  'customer-log.reward.new': 'Nová odměna: {0}x',
  'customer-log.reward.use': 'Využití odměny: {0}x.',

  'date.today': 'Dnes',
  'date.yesterday': 'Včera',
  'date.year.current': 'Tento rok',
  'date.month.current': 'Tento měsíc',
  'date.month.last': 'Minulý měsíc',
  'date.week.last': 'Minulý týden',
  'date.week.current': 'Tento týden',

  'admin.label': 'Administrace',

  'companies.label': 'Společnosti',
  'company.label': 'Detail',

  'page.title': 'Administrační rozhraní - Stammgast.cz',

  'pass.contacts.title': '📞 Kontakty',

  'pass.data.published.title': 'Publikované veze',
  'pass.data.draft.title': 'Uložené změny',
  'pass.publish.success': 'Změny byly publikovány.',
  'pass.publish.confirmation': 'Změny obsahu můžete publikovat pouze 1x za den. Chcete opravdu pokračovat?',
  'pass.publish.all.confirmation': 'Akce způsobí přegenerování kartiček u všech zákazníků. Jedná se o náročnou operaci. Chcete opravdu pokračovat?',
  'pass.publish.label': 'Publikovat',
  'pass.publish.not.available': 'Další změnu obsahu karty můžete publikovat až zítra.',
  'pass.sections.title': 'Informační sekce',
  'pass.section.new': 'Nová sekce',
  'pass.label': 'Obsah karty',
  'pass.edit.label': 'Úprava obsahu karty',
  'pass.update.all': 'Aktualizovat karty',

  'customer-logs.label': 'Přehled událostí',
  'customers.label': 'Registrovaní zákazníci',
  'customers.detail.label': 'Detail',
  'news.label': 'Aktuality',
  'settings.label': 'Nastavení',
  'settings.company.label': 'Firma',
  'stammgast.label': 'Stammgast',

  'program.label': 'Program',

  'program.valid.label': 'Plastnost',
  'program.name.label': 'Název programu',
  'program.description.label': 'Popis',
  'program.maxStamps.label': 'Limit bodů',
  'program.rewardExpiration.label': 'Expirace',
  'program.new.title': 'Nový program',
  'program.edit.title': 'Upravit program',
  'program.validFrom.label': 'Platnost od',
  'program.validTo.label': 'Platnost do',

  'programApply.edit.title': 'Upravit stav konta',

  'rewardApply.stamps': 'Razítka',
  'rewardApply.reward.current': 'Odměny aktuálně',
  'rewardApply.reward.used': 'Odměny využité',

  'users.label': 'Uživatelé',

  'user.label': 'Uživatel',
  'user.firstname.label': 'Jméno',
  'user.surname.label': 'Příjmení',
  'user.create.modal.set.password.label': 'Nastavit heslo',
  'user.name.label': 'Jméno',
  'user.email.label': 'E-mail/login',
  'user.lastLogin.label': 'Poslední přihlášení',
  'user.new.title': 'Nový uživatel',
  'user.edit.title': 'Upravit uživatele',

  'value.country.label.CZE': 'Česká republika',
  'value.country.label.SVK': 'Slovenská republika',

  'value.contact-type.label.PHONE': 'Telefon',
  'value.contact-type.label.EMAIL': 'E-mail',
  'value.contact-type.label.WEB': 'Web',

  'value.news-data-state.label.PUBLISHED': 'Aktuální',
  'value.news-data-state.label.DRAFT': 'Plánována',
  'value.news-data-state.label.ARCHIVED': 'Archiv',

  'value.role-type.label.COMPANY_ADMIN': 'Administrace firmy',
  'value.role-type.label.MOBILE_APP_USER': 'Mobilní aplikace',
  'value.role-type.label.SUPER_ADMIN': 'Systémová administrace'
}

export const datePickerMessages = {
  search: 'Hledat',
  notfound: 'Nic se nenašlo',
  categories: {
    search: 'Výsledek hledání',
    recent: 'Nejčastěji používané',
    people: 'Smileys & lídé',
    nature: 'Zvířata & príroda',
    foods: 'Jídlo & pití',
    activity: 'Activity',
    places: 'Cestování & místa',
    objects: 'Objekty',
    symbols: 'Symboly',
    flags: 'Vlajky',
    custom: 'Vlastní'
  }
}

/* eslint-disable */
export const validations = {
  alpha: () => 'Pole může obsahovat jen písmena.',
  alpha_dash: () => 'Pole může obsahovat jen písmena, číslice, tečky a podtržítka.',
  alpha_num: () => 'Pole může obsahovat jen písmena a číslice.',
  alpha_spaces: () => 'Pole může obsahovat jen písmena, číslice a mezery.',
  between: (field: string, { min, max }: { min: number, max: number }) => `Hodnota pole musí být mezi ${min} a ${max}.`,
  confirmed: () => 'Hodnota položky není stejná.',
  decimal: (field: string, { decimals }: { decimals: number }) => `Hodnota pole musí být číselná a smí obsahovat ${decimals} desatinná místa.`,
  digits: (field: string, { length }: { length: number }) => `Pole musí obsahovat ${length} ${length < 5 ? 'čísla' : 'čísel'}.`,
  dimensions: (field: string, { width, height }: {width: number, height: number}) => `Obrázek musí mít ${width} x ${height} pixlov.`,
  email: () => 'Pole musí obsahovat správnou e-mailovou adresu.',
  excluded: () => 'Nesprávná hodnota.',
  ext: () => 'Není platný soubor.',
  image: () => 'Není obrázek.',
  oneOf: () => 'Pole má nesprávnou hodnotu.',
  length: (field: string, { length }: { length: number }) => `Požadovaný počet znaků: ${length}.`,
  max: (field: string, { length }: { length: number }) => `Pole může obsahovat najvíc ${length} znaků.`,
  max_value: (field: string, { max }: { max: number }) => `Hodnota pole musí být maximálně ${max}.`,
  mimes: () => 'Nesprávny typ souboru.',
  min: (field: string, { length }: { length: number }) => `Pole musí obsahovat minimálně ${length} ${length < 4 ? 'znaky' : 'znaků'}.`,
  min_value: (field: string, { min }: { min: number }) => `Hodnota pole musí být minimálně ${min}.`,
  numeric: () => 'Pole může obsahovat jen číslice.',
  regex: () => 'Formát položky je nesprávný.',
  required: () => 'Pole je povinné.',
  required_if: () => 'Pole je povinné.',
  size: (field: string, { size }: { size: number }) => `Hodnota pole musí být méně jak ${FORMAT_FILE_SIZE(size)}.`
}
/* eslint-enable */
