
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import AppState from '@/modules/app/store/AppState'

/**
 * "id" has to be a value in initial state of app.loadingData array.
 */
@Component
export default class Loading extends Vue {
  @Prop(String) id!: string
  @Prop({ type: String, default: 'primary' }) variant!: string
  @State('app') appState!: AppState

  get loading () {
    return this.appState.loadingData.indexOf(this.id) >= 0
  }
}
