import _ from 'lodash'
import companyResource from '@/modules/company/services/companyResource'
import EntityService from '@/modules/common/services/entityService'
import moment from 'moment'
import Pass from '@/modules/pass/domain/pass'
import passResource from '@/modules/pass/services/passResource'
import runAuthorizedRequest from '@/auth/oauthUtils'

class PassService extends EntityService<Pass> {
  newPass (companyUuid: string, passData?: Pass) {
    const pass = new Pass({
      company: {
        uuid: companyUuid
      }
    })
    if (passData) {
      pass.contacts = _(passData.contacts).map(section => _.omit(section, 'uuid')).value()
      pass.sections = _(passData.sections).map(section => _.omit(section, 'uuid')).value()
    }
    return pass
  }

  async publish (pass: Pass) {
    pass.published = moment()
    return await this.update(pass)
  }

  async publishAll (companyUuid: string) {
    try {
      return await runAuthorizedRequest(() => companyResource.publishAll({ uuid: companyUuid }, null))
    } catch (e) {
      return e
    }
  }
}

export default new PassService(passResource, Pass)
