
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import passService from '@/modules/pass/services/passService'
import Component from 'vue-class-component'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import Pass from '@/modules/pass/domain/pass'
import passForm from '@/modules/pass/components/passForm.vue'
import { Getter, State } from 'vuex-class'
import Vue from 'vue'

@Component({
  components: { loading, page, passForm }
})
export default class PassEditPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'pass.edit.label' }])
  pass: Pass | null = null

  @Getter('draft', { namespace: 'pass' }) draft!: Pass
  @Getter('published', { namespace: 'pass' }) published?: Pass
  @State('managed', { namespace: 'company' }) company!: Company

  async created () {
    await this.$store.dispatch('pass/getAll', new EntityFetchParams(true, { company: this.company.uuid }))
    this.pass = this.draft || passService.newPass(this.company.uuid!, this.published)
  }
}
