import newsPage from '@/modules/news/components/newsPage.vue'

export default [
  {
    path: '/news',
    name: 'news',
    component: newsPage,
    meta: {
      requiresLoggedIn: true,
      requiredCompanyRole: 'COMPANY_ADMIN'
    }
  }
]
