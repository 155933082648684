
import Vue from 'vue'
import Component from 'vue-class-component'
import textField from '@/modules/common/components/form/textField.vue'
import selectField from '@/modules/common/components/form/selectField.vue'
import { Prop } from 'vue-property-decorator'
import Address from '@/modules/company/domain/address'
import countries from '@/modules/common/values/countries'

@Component({
  components: { selectField, textField }
})
export default class AddressForm extends Vue {
  countries = countries
  @Prop({ type: Address, required: true }) address!: Address
}
