
import Component from 'vue-class-component'
import clickOutside from 'v-click-outside'
import data from 'emoji-mart-vue-fast/data/apple.json'
import { datePickerMessages } from '@/i18n/cs'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import { Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Vue from 'vue'
import { removeInvalidChars } from '@/utils'

@Component({
  components: { Picker, ValidationProvider },
  directives: { clickOutside: clickOutside.directive }
})
export default class TextField extends Vue {
  innerValue?: string | null = null
  emojiVisible = false
  datePickerMessages = datePickerMessages
  emojiIndex = new EmojiIndex(data)

  @Prop({ type: String, default: '' }) value!: string
  @Prop({ type: String, default: 'text' }) type!: string
  @Prop({ type: String }) label?: string
  @Prop({ type: String, default: '' }) placeholder!: string
  @Prop({ type: String, required: false }) cssClass?: string
  @Prop({ type: String, required: true }) fieldId?: string
  @Prop({ type: Boolean, required: false }) readonly?: boolean
  @Prop({ type: Number, required: false, default: 100000 }) maxlength?: number
  @Prop({ type: String }) validation!: string
  @Prop({ type: Boolean, default: false }) required!: boolean
  // eslint-disable-next-line
  @Prop({ type: Object, default: () => {} }) validationObj!: any
  @Prop({ type: Boolean, default: false }) focus!: boolean
  @Prop({ type: Boolean, default: true }) showLabel!: boolean
  @Prop({ type: Boolean, default: true }) showInput!: boolean
  @Prop({ type: Boolean, default: false }) showEmoji?: boolean
  @Prop({ type: Boolean, default: false }) emojiRight?: boolean

  paste (e: Event) {
    this.$emit('paste', e)
  }

  get requiredVal () {
    return this.required || ((this.validation || '').indexOf('required') >= 0) || (this.validationObj || {}).required
  }

  @Watch('innerValue')
  onInnerValueChange (value: string) {
    this.$emit('input', removeInvalidChars(value))
  }

  @Watch('value')
  onValueChange (value: string) {
    this.innerValue = removeInvalidChars(value)
  }

  get emojiButtonSvgId () {
    return 'emojiButton-svg-' + this.fieldId
  }

  get emojiButtonPathId () {
    return 'emojiButton-path-' + this.fieldId
  }

  addEmoji (emoji: any) {
    const textarea = this.$refs.textfield as HTMLInputElement
    const cursorPosition = textarea.selectionEnd
    const start = this.value.substring(0, textarea.selectionStart || 0)
    const end = this.value.substring(textarea.selectionStart || 0)
    const text = start + emoji.native + end
    this.$emit('input', text)
    textarea.focus()
    this.emojiVisible = false
    this.$nextTick(() => {
      textarea.selectionEnd = cursorPosition + emoji.native.length
    })
  }

  // hide emoji picker when clicking outside of picker
  onClickOutside (event: any) {
    // do not hide emoji window when button is clicked
    if (event.target.id !== this.emojiButtonSvgId && event.target.id !== this.emojiButtonPathId) {
      this.emojiVisible = false
    }
  }

  created () {
    if (this.value) {
      this.innerValue = removeInvalidChars(this.value)
    }
  }
}
