
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'

@Component
export default class Truncate extends Vue {
  show = false

  @Prop({ type: String }) content?: string
  @Prop({ type: Number, default: 100 }) minLength?: number
}
