import { Entity } from '@/modules/common/domain/entity'

export default class PassSection extends Entity {
  title?: string | null
  content?: string | null

  constructor (data: any) {
    super(data)
    this.title = data.title || null
    this.content = data.content || null
  }
}
