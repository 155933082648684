import resource from '@/modules/certificate/service/certificateResource'
import runAuthorizedRequest from '@/auth/oauthUtils'
import _ from 'lodash'
import Certificate from '@/modules/certificate/domain/certificate'

class CertificateService {
  async generateApnUrl (cert: Certificate): Promise<String | null> {
    try {
      const response = await runAuthorizedRequest(() => resource.save({}, _(cert).omitBy(_.isNil).value()))
      return response.ok ? response.data.apnUrl : null
    } catch (e) {
      return null
    }
  }

  async generate (cert: Certificate) {
    try {
      const response = await runAuthorizedRequest(() => resource.update({}, _(cert).omitBy(_.isNil).value()))
      return response.ok ? true : null
    } catch (e) {
      return null
    }
  }
}

export default new CertificateService()
