import notificationService from '@/modules/common/services/notificationService'
import { HttpResponse } from 'vue-resource/types/vue_resource'

const ignoredCodes: string[] = [
  'auth.login.user.inactive'
]

export default () => {
  return () => {
    return function (response: HttpResponse) {
      if (response.status !== 200) {
        if (response.status === 400) {
          if (response.data.errors && response.data.errors.length) {
            const errorCode = response.data.errors[0].code || 'data.invalid'
            if (ignoredCodes.indexOf(errorCode) < 0) {
              notificationService.error('error.' + errorCode)
            }
          } else if (response.data.message && response.data.args) {
            notificationService.error('error.' + response.data.message, response.data.args)
          } else {
            notificationService.error('error.' + response.data.message)
          }
        } else if (response.status === 401) { // unauthorized error
          notificationService.error('error.auth.invalid')
        } else if (response.status === 404) { // not found
          notificationService.error('error.data.notFound')
        } else if (response.status === 413) { // unauthorized error
          notificationService.error('error.data.payloadTooLarge')
        } else if (response.data && response.data.message) { // general error
          // console.error(VueNotifications.error)
          notificationService.error(response.data.message)
        }
      }
    }
  }
}
