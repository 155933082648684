import _ from 'lodash'
import AuthState from '@/modules/auth/store/authState'
import { GetterTree } from 'vuex'
import RootState from '@/store/rootState'
import { RoleType } from '@/modules/user/type/roleType'

const getters: GetterTree<AuthState, RootState> = {
  loggedIn: (state: AuthState) => {
    return !!state.accessToken && !!state.user
  },
  hasRole: (state: AuthState, getters: any) => (role: RoleType | string) => {
    return getters.loggedIn && state.user!.hasRole(role as RoleType)
  },
  hasCurrentCompanyRole: (state: AuthState, getters: any, rootState: RootState) => (role: RoleType | string) => {
    return getters.loggedIn && rootState.company.managed && state.user!.hasRole(role as RoleType, rootState.company.managed.uuid)
  },
  hasCompanyRole: (state: AuthState, getters: any) => (companyUuid: string, role: RoleType | string) => {
    return getters.loggedIn && state.user!.hasRole(role as RoleType, companyUuid)
  },
  hasAnyCompanyRole: (state: AuthState, getters: any) => (companyUuid: string, ...roles: RoleType[] | string[]) => {
    return getters.loggedIn && state.user!.hasAnyRole(roles as RoleType[])
  },
  hasAnyCurrentCompanyRole: (state: AuthState, getters: any, rootState: RootState) => (roles: RoleType[] | string[]) => {
    return getters.loggedIn && rootState.company.managed && state.user!.hasAnyRole(roles as RoleType[], rootState.company.managed.uuid)
  },
  data: (state: AuthState) => {
    return _.pick(state, ['accessToken', 'accessTokenValidTo', 'refreshToken', 'refreshTokenValidTo', 'fcmToken', 'user'])
  }
}

export default getters
