import { ActionTree, Commit, Dispatch } from 'vuex'
import { AUTH_CLEAR_TOKEN, AUTH_LOG_OUT, AUTH_STORE_ME, AUTH_STORE_TOKEN } from './authMutationTypes'
import authService from '../services/authService'
import AuthState from '@/modules/auth/store/authState'
import Credentials from '@/modules/auth/domain/credentials'
import eventBus from '@/modules/common/services/eventBus'
import RootState from '@/store/rootState'
import moment from 'moment'
import Response from '@/modules/common/domain/response'
import userService from '@/modules/user/services/userService'

async function processLoginResponse (response: Response, dispatch: Dispatch, commit: Commit) {
  if (response.success) {
    await storeTokens(response, commit)
    const user = await userService.me()
    if (!user || !user.hasLoginPermission()) {
      await commit(AUTH_CLEAR_TOKEN)
      return new Response('error.auth.permission')
    }
    await commit(AUTH_STORE_ME, { user })
    await dispatch('loadData')
  }
}

async function storeTokens (response: Response, commit: Commit) {
  if (response.success) {
    const tokenData: any = {
      accessToken: response.data.access_token,
      accessTokenValidTo: moment().add(response.data.expires, 's')
    }
    if (response.data.refresh_token) {
      tokenData.refreshToken = response.data.refresh_token
      tokenData.refreshTokenValidTo = moment().add(response.data.refresh_expires, 's')
    }
    await commit(AUTH_STORE_TOKEN, { ...tokenData })
  }
}

const authActions: ActionTree<AuthState, RootState> = {
  async logIn ({ dispatch, commit }, credentials: Credentials) {
    await dispatch('app/loadingDataEnable', 'login', { root: true })
    const response = await authService.login(credentials)
    const errResponse = await processLoginResponse(response, dispatch, commit)
    await dispatch('app/loadingDataDisable', 'login', { root: true })
    return errResponse || response
  },
  async refreshToken ({ commit, state }) {
    const response = await authService.refreshToken(state.refreshToken!)
    if (response.success) {
      await storeTokens(response, commit)
    }
    return response
  },
  async loadData ({ dispatch, state }) {
    if (state.user && state.user.companyUuid) {
      await dispatch('company/selectManaged', state.user.companyUuid, { root: true })
    }
  },
  async logOut ({ dispatch, commit }, apiLogout = true) {
    if (apiLogout) {
      await authService.logOut()
    }
    await commit(AUTH_LOG_OUT)
    await dispatch('app/loadingDataEnable', 'logout-in-progress', { root: true })
    await eventBus.$emit('logout')
  }
}

export default authActions
