import User from '../domain/user'
import EntityService from '@/modules/common/services/entityService'
import userResource from './userResource'
import runAuthorizedRequest from '@/auth/oauthUtils'

class UserService extends EntityService<User> {
  newUser () {
    return new User({})
  }

  async me () {
    try {
      const response = await runAuthorizedRequest(() => userResource.me())
      if (response.ok) {
        return new User(response.data)
      } else {
        return null
      }
    } catch (e) {
      return null
    }
  }
}

export default new UserService(userResource, User)
