import Company from '@/modules/company/domain/company'
import { Entity } from '@/modules/common/domain/entity'
import { NewsDataType } from '@/modules/news/types/newsDataType'
import moment from 'moment'
import User from '@/modules/user/domain/user'

export default class NewsData extends Entity {
  published?: moment.Moment | null
  content?: string
  company?: Company | null
  user?: User | null

  constructor (data: any) {
    super(data)
    this.published = this.convertDate(data.published)
    this.content = data.content
    this.company = data.company ? new Company(data.company) : null
    this.user = data.user ? new User(data.user) : null
  }

  get state () {
    return this.published ? NewsDataType.PUBLISHED : NewsDataType.DRAFT
  }
}
