

import Credentials from '@/modules/auth/domain/credentials'
import Component, { mixins } from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import notificationService from '@/modules/common/services/notificationService'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import Response from '@/modules/common/domain/response'

@Component({
  components: { loading, vForm, textField },
  mixins: [validationMixin]
})
export default class Login extends mixins(validationMixin, submitProtectionMixin) {
  credentials = new Credentials()

  async onSubmit () {
    await this.$store.dispatch('app/loadingDataEnable', 'login')

    const response = await this.$store.dispatch('auth/logIn', this.credentials) as Response

    if (response.success) {
      await this.$router.push('/')
    } else {
      if (response.error !== 'error.auth.invalid') {
        notificationService.error(response.error!)
      }
    }
    await this.$store.dispatch('app/loadingDataDisable', 'login')
  }
}
