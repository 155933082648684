import _ from 'lodash'
import { createGetters } from '@/modules/common/store/entityGetters'
import EntityState from '@/modules/common/store/entityState'
import { GetterTree } from 'vuex'
import Pass from '@/modules/pass/domain/pass'
import RootState from '@/store/rootState'

const getters: GetterTree<EntityState<Pass>, RootState> = createGetters()

getters.published = (state) => {
  return _.find(state.items, 'isPublished')
}

getters.draft = (state) => {
  return _.find(state.items, 'isDraft')
}

export default getters
