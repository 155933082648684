

import $ from 'jquery'
import Component from 'vue-class-component'
import { Getter } from 'vuex-class'
import Vue from 'vue'
import { RoleType } from '@/modules/user/type/roleType'

@Component
export default class Sidebar extends Vue {
  @Getter('hasRole', { namespace: 'auth' }) hasRole!: (role: RoleType | string) => boolean
  @Getter('hasCurrentCompanyRole', { namespace: 'auth' }) hasCurrentCompanyRole!: (role: RoleType | string) => boolean

  mounted () {
    // need to init main sidebar manually - do not work automatically after login
    const element: any = $(this.$refs.sidebar!)
    element.Treeview('init')
  }
}
