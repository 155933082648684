import EntityService from '@/modules/common/services/entityService'
import ProgramApply from '@/modules/program/domain/programApply'
import programApplyResource from './programApplyResource'

class ProgramApplyService extends EntityService<ProgramApply> {
  newProgramApply (companyUuid: string, customerUuid: string, programUuid: string) {
    return new ProgramApply(
      {
        current: 0,
        company: {
          uuid: companyUuid
        },
        customer: {
          uuid: customerUuid
        },
        program: {
          uuid: programUuid
        }
      })
  }
}

export default new ProgramApplyService(programApplyResource, ProgramApply)
