import moment from 'moment'
import Labeled from '@/modules/common/values/labeled'
import Searchable, { buildSearchString } from '@/modules/common/values/searchable'

class Entity implements Labeled, Searchable {
  uuid?: string | null
  created?: moment.Moment | null
  value: any
  label: string
  searchString: string
  labelDescription?: string

  constructor (data?: any, idProp = 'uuid', labelProp = 'label', searchProps?: Array<string>) {
    this.uuid = data.uuid
    if (data && data.created) {
      this.created = this.convertDate(data.created)
    }

    this.value = data[idProp] || ''
    this.label = data[labelProp] || ''
    this.searchString = buildSearchString(this, searchProps || ['label'])
  }

  convertDate (date: any): moment.Moment | null {
    return date ? moment(date) : null
  }
}

export { Entity }

export const GENERATED_PROPS = ['label', 'searchString', 'value']
