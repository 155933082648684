import '@/modules/common/directives'
import './resourceInit'
import router from './router'
import store from './store'
import filters from '@/modules/common/filters'
import interceptors from '@/interceptors'
import hooks from '@/hooks'
import 'bootstrap'
import BootstrapVue from 'bootstrap-vue'
import 'admin-lte/plugins/jquery/jquery'
import 'admin-lte/dist/js/adminlte' // it must be here for loading adminLte
import App from '@/modules/app/components/app.vue'
import { initOauth } from '@/auth/oauthUtils'
import validationsInit from '@/modules/common/validation'
import notificationService from '@/modules/common/services/notificationService'
import Vue from 'vue'
import urlCleanHook from '@/hooks/router/urlCleanHook'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n/i18n'
import VueMeta from 'vue-meta'

// first step, clean invalid URL, e.g. facebook fbclid etc. Continue only if url was not cleared
if (!urlCleanHook()) {
  notificationService.init()

  validationsInit()

  filters()

  // register http interceptor
  interceptors(store, router)

  // register hooks
  hooks(store, router)

  // init auth resource
  initOauth(store)

  // init bootstrap-vue
  Vue.use(BootstrapVue)

  Vue.config.productionTip = false

  Vue.use(VueMeta)

  // need to load data first
  await store.dispatch('auth/loadData')

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
  }).$mount('#app')

  Vue.use(VueClipboard)
}
