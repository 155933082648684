
import _ from 'lodash'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import Company from '@/modules/company/domain/company'
import Component from 'vue-class-component'
import loading from '@/modules/common/components/loading.vue'
import page from '@/modules/common/components/page.vue'
import { State } from 'vuex-class'
import termsCreateModal from '@/modules/company/components/termsCreateModal.vue'
import Vue from 'vue'

@Component({
  components: { loading, page, termsCreateModal }
})
export default class CompanySettingPage extends Vue {
  companyToEdit?: Company | null = null
  showCreateModal = false
  breadCrumbData = new BreadCrumbData([{ textKey: 'settings.label' }, { textKey: 'settings.company.label' }])

  @State('managed', { namespace: 'company' }) company!: Company

  updateShow () {
    this.companyToEdit = _.cloneDeep(this.company)
    this.showCreateModal = true
  }

  createHide () {
    this.showCreateModal = false
  }

  async fetch () {
    await this.$store.dispatch('company/refreshManaged')
  }
}
