
import Vue from 'vue'
import Component from 'vue-class-component'
import page from '@/modules/common/components/page.vue'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import newsTable from '@/modules/news/components/newsTable.vue'
import infoBox from '@/modules/common/components/infoBox.vue'
import newsInfoBox from '@/modules/news/components/newsInfoBox.vue'

@Component({
  components: { infoBox, newsInfoBox, newsTable, page }
})
export default class NewsDataPage extends Vue {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'news.label' }])
}
