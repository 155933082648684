import usersPage from '@/modules/user/components/usersPage.vue'
import { RoleType } from '@/modules/user/type/roleType'

export default [
  {
    path: '/admin/users',
    component: usersPage,
    name: 'users',
    meta: {
      requiresLoggedIn: true,
      requiredCompanyRole: RoleType.COMPANY_ADMIN
    }
  }
]
