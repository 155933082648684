import Vue from 'vue'
import { API_ROOT } from '@/config'

const contextPath = 'companies'

const customActions = {
  publishAll: { method: 'POST', url: API_ROOT + contextPath + '/{uuid}/publish' }
}

export default (<any>Vue).resource(API_ROOT + contextPath + '{/uuid}', {}, customActions)
