
import _ from 'lodash'
import Component from 'vue-class-component'
import Company from '@/modules/company/domain/company'
import loading from '@/modules/common/components/loading.vue'
import { Prop } from 'vue-property-decorator'
import { State } from 'vuex-class'
import termsCreateModal from '@/modules/company/components/termsCreateModal.vue'
import truncate from '@/modules/common/components/truncate.vue'
import User from '@/modules/user/domain/user'
import Vue from 'vue'

@Component({
  components: { termsCreateModal, loading, truncate }
})
export default class CompanyInfoBox extends Vue {
  @Prop({ type: Company, required: true }) company!: Company
  @State('items', { namespace: 'user' }) users!: User[]

  companyToEdit: Company | null = null
  showTermsModal = false

  get admin () {
    return _.first(this.users!)
  }

  updateShow () {
    this.companyToEdit = _.cloneDeep(this.company)
    this.showTermsModal = true
  }

  updateHide () {
    this.showTermsModal = false
  }

  async fetch () {
    await this.$store.dispatch('company/refreshSelected')
  }

  async created () {
    await this.$store.dispatch('user/clearAll')
  }
}
