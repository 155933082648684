import _ from 'lodash'
import Customer from '@/modules/customer/domain/customer'
import moment from 'moment'
import { Entity } from '@/modules/common/domain/entity'
import CustomerReward from '@/modules/reward/domain/customerReward'
import Program from '@/modules/program/domain/program'

export default class ProgramApply extends Entity {
  program?: Program | null
  customer?: Customer | null
  rewards: Array<CustomerReward> = []
  current?: number | null
  updated?: moment.Moment | null

  constructor (data: any) {
    super(data, 'uuid', 'uuid')
    this.customer = _.isNil(data.customer) ? null : new Customer(data.customer)
    this.program = _.isNil(data.program) ? null : new Program(data.program)
    this.current = _.isNil(data.current) ? null : data.current
    this.rewards = data.rewards ? data.rewards.map((item: any) => new CustomerReward(item)) : []
    this.updated = this.convertDate(data.updated)
  }

  get rewardsAvailable () {
    return _.filter(this.rewards, { used: false }).length
  }

  async updateRewardsAvailable (value: number) {
    if (!_.isNil(value) && value !== this.rewardsAvailable) {
      const notUsed = _.filter(this.rewards, { used: false })
      // if new number of rewards is higher than current, add new rewards
      if (value > notUsed.length) {
        _.times(value - notUsed.length, () => {
          this.rewards.push(new CustomerReward({
            used: false
          }))
        })
      } else if (value === 0) { // if new required number of rewards is 0, mar all not used reward as used
        notUsed.forEach(reward => {
          reward.used = true
        })
      } else { // if new number of rewards is less than current, mark diff number of rewards as used. Take the oldest rewards.
        _(notUsed)
          .sortBy('validTo')
          .take(notUsed.length - value)
          .forEach(reward => {
            reward.used = true
          })
      }
    }
  }

  get rewardsUsed () {
    return _.filter(this.rewards, { used: true }).length
  }
}
