import Credentials from '@/modules/auth/domain/credentials'
import { Route } from 'vue-router'
import User from '@/modules/user/domain/user'
import { RoleType } from '@/modules/user/type/roleType'
import Vue from 'vue'
import { API_ROOT, LOGIN_URL, LOGOUT_URL, REFRESH_TOKEN_URL } from '@/config'
import Response, { OK } from '@/modules/common/domain/response'

const REDIRECT_TO_PARAM = 'redirectTo'
const AUTH_BASIC_HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
  Accept: 'application/json'
}

class AuthService {
  async login (credentials: Credentials) {
    try {
      const data = new FormData()
      data.set('username', <string>credentials.username)
      data.set('password', <string>credentials.password)
      data.set('captchaToken', <string>credentials.captchaToken)
      const response = await (<any>Vue).http.post(LOGIN_URL, data, { headers: AUTH_BASIC_HEADERS })
      return Response.fromHttpResponse(response)
    } catch (err) {
      return Response.fromHttpResponse(err)
    }
  }

  async refreshToken (refreshToken: string) {
    try {
      const data = new FormData()
      data.set('refresh_token', refreshToken)
      const response = await (<any>Vue).http.post(REFRESH_TOKEN_URL, data, { headers: AUTH_BASIC_HEADERS })
      return Response.fromHttpResponse(response)
    } catch (err) {
      return Response.fromHttpResponse(err)
    }
  }

  loginPath (route: Route) {
    let path = '/login'
    if (route.query[REDIRECT_TO_PARAM]) {
      path += `?${REDIRECT_TO_PARAM}=${route.query[REDIRECT_TO_PARAM]}`
    } else if (route.name !== 'login') {
      path += `?${REDIRECT_TO_PARAM}=${encodeURI(route.fullPath)}`
    }
    return path
  }

  async logOut () {
    try {
      await (<any>Vue).http.post(API_ROOT + LOGOUT_URL)
      return OK
    } catch (err) {
      return Response.fromHttpResponse(err as any)
    }
  }

  mainPath (currentRoute: Route, user: User) {
    const query = {}
    const redirectToVal = currentRoute.query[REDIRECT_TO_PARAM]
    let path
    if (redirectToVal) {
      path = redirectToVal as string
    } else if (user.hasAnyCompanyRole(RoleType.COMPANY_ADMIN)) {
      path = '/news'
    } else if (user.hasRole(RoleType.SUPER_ADMIN)) {
      path = '/admin/companies'
    } else {
      throw new Error('missing.permission')
    }
    return { path, query }
  }
}

export default new AuthService()
