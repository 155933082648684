import NewsData from '@/modules/news/domain/newsData'
import EntityService from '@/modules/common/services/entityService'
import newsDataResource from '@/modules/news/services/newsDataResource'
import moment from 'moment'

class NewsDataService extends EntityService<NewsData> {
  newNewsData (companyUuid: string): NewsData {
    return new NewsData({
      company: {
        uuid: companyUuid
      }
    })
  }

  async publish (newsData: NewsData) {
    if (!newsData.published) {
      newsData.published = moment()
      return await this.update(newsData)
    } else {
      throw new Error('error.newsData.publish.alreadyPublished')
    }
  }
}

export default new NewsDataService(newsDataResource, NewsData)
