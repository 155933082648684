import { Entity } from '@/modules/common/domain/entity'
import { ContactType } from '@/modules/pass/types/contactType'

export default class Contact extends Entity {
  type?: ContactType | null
  value: any
  name?: string | null

  constructor (data: any) {
    super(data)
    this.type = data.type || null
    this.value = data.value || null
    this.name = data.name || null
  }

  get link () {
    switch (this.type) {
      case ContactType.EMAIL:
        return '📧 ' + this.name
      case ContactType.PHONE:
        return '☎️ ' + this.name
      case ContactType.WEB: {
        return '🌐 ' + this.name
      }
      default: return null
    }
  }

  get href () {
    switch (this.type) {
      case ContactType.EMAIL:
        return 'mailto:' + this.value
      case ContactType.PHONE:
        return 'tel:' + this.value
      case ContactType.WEB: {
        const contactValue = this.value!
        return contactValue.toLocaleLowerCase().startsWith('http') ? contactValue : 'http://' + contactValue
      }
      default: return null
    }
  }

  get target () {
    return this.type === 'WEB' ? '_blank' : ''
  }
}
