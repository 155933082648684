import EntityService from '@/modules/common/services/entityService'
import Program from '@/modules/program/domain/program'
import programResource from '@/modules/program/services/programResource'

class ProgramService extends EntityService<Program> {
  newProgram (companyUuid: string) {
    return new Program({ company: { uuid: companyUuid } })
  }
}

export default new ProgramService(programResource, Program)
