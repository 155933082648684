import { AUTH_STORAGE_KEY } from '@/config'
import actions from './authActions'
import AuthState from '@/modules/auth/store/authState'
import getters from './authGetters'
import moment from 'moment'
import mutations from './authMutations'
import { Module } from 'vuex'
import RootState from '@/store/rootState'
import User from '@/modules/user/domain/user'

// Local storage sync state
const authData = localStorage.getItem(AUTH_STORAGE_KEY)
const storedData = authData ? JSON.parse(authData) : null
let initialState: any

if (storedData && storedData.user) { // sometime user is not stored (missing in response of refresh token)
  initialState = new AuthState(storedData.accessToken,
    moment(storedData.accessTokenValidTo),
    storedData.refreshToken,
    moment(storedData.refreshTokenValidTo),
    new User(storedData.user))
} else {
  localStorage.removeItem(AUTH_STORAGE_KEY) // only for cleanup
  initialState = new AuthState()
}

const authModule: Module<AuthState, RootState> = {
  namespaced: true,
  state: initialState,
  getters,
  mutations,
  actions
}
export default authModule
