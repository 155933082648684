import moment from 'moment/moment'

class CertificateData {
  uuid: string
  validTo: moment.Moment

  constructor (uuid: string, validTo: moment.Moment) {
    this.uuid = uuid
    this.validTo = validTo
  }
}

export default CertificateData
