
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import dateTimeField from '@/modules/common/components/form/dateTimeField.vue'
import formModal from '@/modules/common/components/formModal.vue'
import checkboxField from '@/modules/common/components/form/checkboxField.vue'
import notificationService from '@/modules/common/services/notificationService'
import numberField from '@/modules/common/components/form/numberField.vue'
import permissionsEditTable from '@/modules/user/components/permissionsEditTable.vue'
import { RoleType } from '@/modules/user/type/roleType'
import staticField from '@/modules/common/components/form/staticField.vue'
import textAreaField from '@/modules/common/components/form/textAreaField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import User from '@/modules/user/domain/user'
import { Prop, Watch } from 'vue-property-decorator'

@Component({
  components: { dateTimeField, formModal, checkboxField, numberField, permissionsEditTable, staticField, textAreaField, textField }
})
export default class UserCreateModal extends mixins(createModal) {
  @Prop({ type: String, required: true }) companyUuid!: string

  moduleName = 'user'
  passwordEnabled = false
  passwordEnabledEditable = true
  PermissionType = RoleType

  @Watch('item')
  onItemChanged (item: User) {
    if (item && !this.isEdit()) {
      this.passwordEnabled = true
      this.passwordEnabledEditable = false
    }
  }

  validate (): boolean {
    if (!this.item.roles.length) {
      notificationService.error('error.user.roles.empty')
      return false
    }
    return true
  }

  beforeSave () {
    if (!this.passwordEnabled) {
      this.item.password = undefined
    }
  }

  beforeClose () {
    this.passwordEnabled = false
    this.passwordEnabledEditable = true
  }
}
