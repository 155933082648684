
import Component from 'vue-class-component'
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class Page extends Vue {
  @Prop({ type: String, required: true }) title?: string
  @Prop({ type: String, required: false, default: 'mb-3' }) titleClass?: string
  @Prop({ type: Object, required: false }) breadCrumbData?: BreadCrumbData
}
