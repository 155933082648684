import { Entity } from '@/modules/common/domain/entity'
import CustomerInfo from '@/modules/customer/domain/customerInfo'
import moment from 'moment'

export default class Customer extends Entity {
  firstname?: string
  surname?: string
  email?: string
  lastActivity?: moment.Moment | null
  active?: boolean

  constructor (data: any) {
    super(data, 'uuid', 'fullName', ['fullName', 'email'])
    this.firstname = data.firstname
    this.surname = data.surname
    this.email = data.email
    this.lastActivity = this.convertDate(data.lastActivity)
    this.active = data.active
  }

  get fullName (): string {
    return ((this.surname || '') + ' ' + (this.firstname || '')).trim()
  }

  get info (): CustomerInfo {
    return new CustomerInfo(this)
  }
}
