
import _ from 'lodash'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import Component, { mixins } from 'vue-class-component'
import Contact from '@/modules/pass/domain/contact'
import { contactTypes } from '@/modules/pass/types/contactType'
import move from 'lodash-move'
import notificationService from '@/modules/common/services/notificationService'
import page from '@/modules/common/components/page.vue'
import { Prop } from 'vue-property-decorator'
import Pass from '@/modules/pass/domain/pass'
import selectField from '@/modules/common/components/form/selectField.vue'
import textAreaField from '@/modules/common/components/form/textAreaField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import validationMixin from '@/modules/common/components/form/validationMixin'
import vForm from '@/modules/common/components/form/vForm.vue'
import PassSection from '@/modules/pass/domain/passSection'
import EntityCreateParams from '@/modules/common/store/entityCreateParams'

@Component({
  components: { page, selectField, textAreaField, textField, vForm }
})
export default class PassForm extends mixins(validationMixin, confirmMixin) {
  contactTypes = contactTypes

  @Prop({ type: Pass, required: true }) model!: Pass

  onSubmit () {
    this.$store.dispatch('pass/' + (this.model.uuid ? 'update' : 'create'), new EntityCreateParams(this.model, false)).then((result: Pass) => {
      if (result) {
        notificationService.success('data.edit.success')
        this.$router.push('/pass')
      }
    }).finally(this.unprotect)
  }

  addContact () {
    this.model.contacts.push(new Contact({}))
  }

  addSection () {
    this.model.sections.push(new PassSection({}))
  }

  deleteSection (section: PassSection) {
    this.model.sections = _.without(this.model.sections, section)
  }

  sectionPositionDown (section: PassSection) {
    const sectionIndex = this.model.sections.indexOf(section)
    const indexOfNextItem = sectionIndex + 1
    // last index check
    if (this.model.sections.length <= indexOfNextItem) {
      return
    }

    this.model.sections = move(this.model.sections, sectionIndex, indexOfNextItem)
  }

  sectionPositionUp (section: PassSection) {
    const sectionIndex = this.model.sections.indexOf(section)
    const indexOfPrevItem = sectionIndex - 1
    // first index check
    if (indexOfPrevItem < 0) {
      return
    }
    this.model.sections = move(this.model.sections, sectionIndex, indexOfPrevItem)
  }

  deleteContact (contact: Contact) {
    this.model.contacts = _.without(this.model.contacts, contact)
  }

  contactPositionDown (contact: Contact) {
    const contactIndex = this.model.contacts.indexOf(contact)
    const indexOfNextItem = contactIndex + 1
    // last index check
    if (this.model.contacts.length <= indexOfNextItem) {
      return
    }

    this.model.contacts = move(this.model.contacts, contactIndex, indexOfNextItem)
  }

  contactPositionUp (contact: Contact) {
    const contactIndex = this.model.contacts.indexOf(contact)
    const indexOfPrevItem = contactIndex - 1
    // first index check
    if (indexOfPrevItem < 0) {
      return
    }
    this.model.contacts = move(this.model.contacts, contactIndex, indexOfPrevItem)
  }
}
