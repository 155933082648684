

import Certificate from '../../certificate/domain/certificate'
import certificateService from '@/modules/certificate/service/certificateService'
import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop } from 'vue-property-decorator'
import Company from '@/modules/company/domain/company'
import notificationService from '@/modules/common/services/notificationService'

@Component
export default class CompanyCertificateBox extends Vue {
  @Prop({ type: Company, required: true }) company!: Company

  async generateApnUrl () {
    const url = await certificateService.generateApnUrl(new Certificate(this.company))
    if (url) {
      window.open(url as string, '_blank', 'noreferrer')
    } else {
      notificationService.error('error.certificate.apn.url.generate')
    }
  }

  async generate () {
    const resp = await certificateService.generate(new Certificate(this.company))
    if (resp) {
      await this.$store.dispatch('company/refreshSelected')
    } else {
      notificationService.error('error.certificate.generate')
    }
  }
}
