
import BreadCrumbData from '@/modules/common/components/breadcrumb/breadcrumbData'
import EntityFetchParams from '@/modules/common/store/entityFetchParams'
import _ from 'lodash'
import moment from 'moment/moment'
import Component, { mixins } from 'vue-class-component'
import Company from '@/modules/company/domain/company'
import confirmMixin from '@/modules/common/mixins/confirmMixin'
import loading from '@/modules/common/components/loading.vue'
import notificationService from '@/modules/common/services/notificationService'
import { Getter, State } from 'vuex-class'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'
import page from '@/modules/common/components/page.vue'
import Pass from '@/modules/pass/domain/pass'
import passDataBox from '@/modules/pass/components/passDataBox.vue'

@Component({
  components: { loading, page, passDataBox }
})
export default class PassContentPage extends mixins(confirmMixin, submitProtectionMixin) {
  breadCrumbData = new BreadCrumbData([{ textKey: 'stammgast.label' }, { textKey: 'pass.label' }])

  @Getter('published', { namespace: 'pass' }) published?: Pass
  @Getter('draft', { namespace: 'pass' }) draft?: Pass
  @State('managed', { namespace: 'company' }) company!: Company

  publish () {
    if (!this.publishAvailable) {
      throw new Error('pass.publish.not.available')
    }
    this.confirm('pass.publish.confirmation').then(async (value: boolean) => {
      if (value) {
        await this.$store.dispatch('pass/publish', this.draft)
        notificationService.success('pass.publish.success')
      }
    })
  }

  get hasChange () {
    return !_.isEqual(_.omit(this.draft, 'updated', 'company', 'created'), _.omit(this.published, ['updated', 'company', 'created']))
  }

  get publishAvailable () {
    return this.draft && (!this.published || this.published!.published!.isBefore(moment().startOf('day')))
  }

  async created () {
    await this.$store.dispatch('pass/getAll', new EntityFetchParams(true, { company: this.company.uuid }))
  }
}
