import { Entity } from '@/modules/common/domain/entity'
import Company from '@/modules/company/domain/company'

class Location extends Entity {
  name?: string
  latitude?: number
  longitude?: number
  notificationText?: string
  company?: Company

  constructor (data: any) {
    super(data)
    this.name = data.name
    this.latitude = data.latitude
    this.longitude = data.longitude
    this.notificationText = data.notificationText
    this.company = new Company(data.company)
  }
}

export default Location
