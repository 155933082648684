import entityActions from '@/modules/common/store/entityActions'
import companyService from '@/modules/company/services/companyService'
import { SELECT_MANAGED } from '@/modules/company/store/companyMutationTypes'
import CompanyState from '@/modules/company/store/companyState'

const loadingId = 'company'

const actions = entityActions(companyService, loadingId)

actions.selectManaged = async function ({ dispatch, commit }, uuid) {
  await commit(SELECT_MANAGED, {
    item: null
  })
  await dispatch('app/loadingDataEnable', loadingId + '-select', { root: true })
  const entity = await companyService.find({ uuid })
  await commit(SELECT_MANAGED, {
    item: entity
  })
  await dispatch('app/loadingDataDisable', loadingId + '-select', { root: true })
  return entity
}

actions.refreshManaged = async function ({ state, dispatch }) {
  const companyState = state as CompanyState
  if (companyState.managed) {
    await dispatch('selectManaged', companyState.managed.uuid)
  }
}

export default actions
