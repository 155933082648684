
import _ from 'lodash'
import eventBus from '@/modules/common/services/eventBus'
import Component, { mixins } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import vForm from '@/modules/common/components/form/vForm.vue'
import { BModal, BvModalEvent } from 'bootstrap-vue'
import submitProtectionMixin from '@/modules/common/mixins/submitProtectionMixin'

@Component({
  components: { BModal, vForm }
})
export default class FormModal extends mixins(submitProtectionMixin) {
  show = false

  @Prop({ type: Boolean, default: false }) showModal!: boolean
  @Prop({ type: String, default: '' }) title!: string
  @Prop({ type: String, default: 'md' }) size!: string
  @Prop({ type: String, default: '' }) footerClass!: string
  @Prop(Object) model!: any

  async submit () {
    await this.protect()
    const isValid = await (this.$refs.form as any).validate()
    if (isValid) {
      await this.$emit('submit')
    } else {
      await this.unprotect()
    }
  }

  onClose (event?: BvModalEvent) {
    if (_.isNil(event) || event.trigger === 'cancel' || event.trigger === 'esc' || event.trigger === 'headerclose') {
      this.unprotect()
      this.$emit('close')
    }
  }

  @Watch('showModal', { immediate: true })
  onShowModalChange (showModal: boolean) {
    this.show = showModal
  }

  created () {
    eventBus.$on('submit', () => {
      if (this.show) {
        this.submit()
      }
    })
    eventBus.$on('close', () => {
      if (this.show) {
        this.onClose()
      }
    })
    // force close for direct change of route without closing window
    eventBus.$on('close-force', () => {
      if (this.show) {
        this.onClose()
      }
    })
  }
}
