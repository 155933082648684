
import Component, { mixins } from 'vue-class-component'
import createModal from '@/modules/common/components/createModal'
import { EntityMap } from '@/modules/common/domain/entityMap'
import formModal from '@/modules/common/components/formModal.vue'
import { Getter } from 'vuex-class'
import Program from '@/modules/program/domain/program'
import selectField from '@/modules/common/components/form/selectField.vue'
import staticField from '@/modules/common/components/form/staticField.vue'
import textField from '@/modules/common/components/form/textField.vue'
import NumberField from '@/modules/common/components/form/numberField.vue'

@Component({
  components: { NumberField, staticField, selectField, textField, formModal }
})
export default class ProgramApplyCreateModal extends mixins(createModal) {
  rewardsAvailable = 0
  moduleName = 'programApply'
  doFetchAfterDefault = false

  @Getter('allById', { namespace: 'program' }) programsById!: EntityMap<Program>

  afterSuccessSave () {
    this.$emit('saved')
  }

  beforeSave () {
    this.item.updateRewardsAvailable(this.rewardsAvailable)
  }

  afterModelSet () {
    this.rewardsAvailable = this.item.rewardsAvailable
  }
}
