import moment from 'moment'
import { Entity } from '@/modules/common/domain/entity'

export default class CustomerReward extends Entity {
  used?: boolean
  validTo?: moment.Moment | null
  constructor (data: any) {
    super(data, 'uuid', 'uuid')
    this.used = data.used
    this.validTo = this.convertDate(data.validTo)
  }
}
